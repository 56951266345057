// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    segmentations: [],
    properties: [],
    opened: false
};

// ==============================|| RULES REDUCER ||============================== //

const segmentationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SEGMENTATIONS:
            return {
                ...state,
                segmentations: action.segmentations
            };
        case actionTypes.SET_PRPOERTIES:
            return {
                ...state,
                properties: action.properties
            };
        case actionTypes.SET_CONDITIONS:
            return {
                ...state,
                conditions: action.conditions
            };
        case actionTypes.SET_RULE_MENU:
            return {
                ...state,
                opened: action.opened
            };
        default:
            return state;
    }
};

export default segmentationsReducer;
