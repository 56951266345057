// material-ui
import { ButtonBase } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    /*<ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <Logo />
    </ButtonBase> */
    <Link href="/" className="navbar-brand" id="logo" />
);

export default LogoSection;
