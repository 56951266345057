import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Login from 'views/pages/authentication/Login';

//errors
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'views/ErrorFallback';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    const currentUser = useSelector((state) => state.session.userId);
    const isUserSuccessfullyLogedIn = useSelector((state) => state.session.isUserSuccessfullyLogedIn);

    if (!currentUser || !isUserSuccessfullyLogedIn) {
        return <Login />;
    } else {
        return (
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                    location.reload();
                }}
            >
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            </ErrorBoundary>
        );
    }
};

export default App;
