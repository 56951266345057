import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const Rules = Loadable(lazy(() => import('views/pages/rules/Rules.js')));
const Rule = Loadable(lazy(() => import('views/pages/rules/rule/Rule.js')));
const NewRule = Loadable(lazy(() => import('views/pages/rules/rule/Actions/NewRule.js')));
const SelectedCompany = Loadable(lazy(() => import('views/pages/authentication/SelectedCompany')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <SelectedCompany />
        },
        {
            path: 'rules',
            children: [
                {
                    path: 'list',
                    element: <Rules />
                },
                {
                    path: 'rule/:internalId',
                    element: <Rule />
                },
                {
                    path: 'rule/create',
                    element: <NewRule />
                }
            ]
        }
    ]
};

export default MainRoutes;
