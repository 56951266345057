import { Card, FormLabel, TextField, TextareaAutosize } from '@mui/material';

export default function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '20%'
      }}
    >
      <div role="alert">
        <FormLabel>Something went wrong:</FormLabel>
        <TextField
          value={error.message}
          disabled
          style={{ width: '100%', marginLeft: '50%' }}
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              style: {
                resize: "auto"
              },
              minRows: 2
            }
          }}
        />
        <button className="btn btn-primary btn-block" onClick={resetErrorBoundary} style={{ width: '100%', marginLeft: '50%', marginTop: '10%', marginBottom: '10%' }}>Try again</button>
      </div>
    </Card>
  )
}