import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { SHOW_NOTIFICATION } from 'store/actions';

const vertical = 'bottom';
const horizontal = 'right';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
    // const [open, setOpen] = React.useState(false);

    const dispatch = useDispatch();

    const open = useSelector((state) => state.customization.showNotification);
    const notification = useSelector((state) => state.customization.notification);

    const handleClick = () => {
        //setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch({ type: SHOW_NOTIFICATION, showNotification: false });
        // setOpen(false);
    };

    return (
        notification &&
        notification.message &&
        notification.severity && (
            <Snackbar
                open={open}
                autoHideDuration={notification.severity === 'success' ? 3000 : 6000}
                onClose={handleClose}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert onClose={handleClose} severity={notification.severity} sx={{ width: '100%' }}>
                    <div>
                        {notification.message?.split('|').map((element) => {
                            return <p>{element}</p>;
                        })}
                    </div>
                </Alert>
            </Snackbar>
        )
        // <Stack spacing={2} sx={{ width: '100%' }}>
        //   <Button variant="outlined" onClick={handleClick}>
        //     Open success snackbar
        //   </Button>
        //   <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        //     <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        //       This is a success message!
        //     </Alert>
        //   </Snackbar>
        //   <Alert severity="error">This is an error message!</Alert>
        //   <Alert severity="warning">This is a warning message!</Alert>
        //   <Alert severity="info">This is an information message!</Alert>
        //   <Alert severity="success">This is a success message!</Alert>
        // </Stack>
    );
}
