import i18n, { use } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translation_es from './assets/locales/es/translation.json';
import login_es from './assets/locales/es/login.json';
import rules_es from './assets/locales/es/rules.json';
import properties_es from './assets/locales/es/properties.json';
import menu_es from './assets/locales/es/menu.json';

i18n
    //load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'es',
        debug: true,
        resources: {
            es: {
                translation: translation_es,
                login: login_es,
                rules: rules_es,
                properties: properties_es,
                menu: menu_es
            }
        },
        interpolation: {
            escapeValue: false //not needed for react as it escapes by default
        }
    });

export default i18n;
