import axios from 'axios';
import qs from 'qs';

import { store } from 'store/index';
import { SET_USER, SET_SEGMENTATIONS, SET_PRPOERTIES, SET_CONDITIONS, LOGOUT } from 'store/actions';
import { initialState } from 'store/sessionReducer';

// request interceptor to add token to request headers
axios.interceptors.request.use(
    async (config) => {
        const token = store.getState().session.accessToken;
        const selectedCompany = store.getState().session.selectedCompany;
        var companyAssociationId = '0';
        var selectedCompanyId = '0';
        if (selectedCompany) {
            companyAssociationId = selectedCompany.companyAssociationId;
            selectedCompanyId = selectedCompany.wholesalerId;
        }
        if (token) {
            config.headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                CompanyAssociationId: companyAssociationId,
                SelectedCompanyId: selectedCompanyId
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use((response) => response, (error) => {
    console.log(error)
    if (error.response.status === 401) {
        if(error?.config?.url !== '/api/Account/token'){
            store.dispatch({ type: LOGOUT });
            window.location = '/';
        }
        else{
            store.dispatch({ type: SET_USER, ...initialState, errorLogin: true, errorMessage: error.response?.data?.Errors?.BusinessException });
        }
    }
    else if(error.response.status !== 200){
        if(error.response?.status === 400 && error.response?.data){
            var message = ''
            Object.entries(error.response.data).map((value) => {
                message += value[1]+'|'
            });
            throw new Error(message)
        }
        else if (error?.response?.data?.errors?.businessException) {
            throw new Error(error?.response?.data?.errors?.businessException);
        }
        else{
            throw new Error(error?.message ? error.message : error)
        }
    }
});


const login = async (username, password) => {
    var data = qs.stringify({
        userName: username,
        password: password
    });

    var config = {
        method: 'post',
        url: '/api/Account/token',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
    };

    await axios(config)
        .then((response) => {
            const user = response?.data;
            //return { user: user, infoRules: getInfoRules(user.AccessToken) };
            console.log(user)
            if (user) {
                store.dispatch({ type: SET_USER, user: user, username: username });
                getInfoRules(user.AccessToken)?.then((info) => {
                    store.dispatch({ type: SET_SEGMENTATIONS, segmentations: info?.segmentations });
                    store.dispatch({ type: SET_PRPOERTIES, properties: info?.properties });
                    store.dispatch({ type: SET_CONDITIONS, conditions: info?.conditions });
                })
            }
        })
        .catch((error) => {
            console.log(error)
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            store.dispatch({ type: SET_USER, user: undefined, username: username, errorLogin: true, errorMessage: resMessage });
        });
};

const logout = () => {

    var config = {
        method: 'get',
        url: '/api/Account/logout'
    };
    return axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw new Error(error)
        });
};

const getInfoRules = async (token) => {

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            CompanyAssociationId: '0',
            SelectedCompanyId: '0'
        }
    }

    try {
        const responseSegmentations = await axios.get('/api/Rules/segmentaciones', headers);
        const responseProperties = await axios.get('/api/Rules/properties', headers);
        const responseConditions = await axios.get('/api/Rules/conditions/groups', headers);
        return { segmentations: responseSegmentations?.data?.segmentaciones, properties: responseProperties?.data, conditions: responseConditions?.data }
    } catch (err) {
        // Handle Error Here
        console.error(err);
        return { segmentations: [], properties: {} }
    }

};

const AuthService = {
    login,
    logout
};

export default AuthService;
