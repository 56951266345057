import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';

import AuthService from 'services/auth.service';
import { SET_USER } from 'store/actions';
import { initialState } from 'store/sessionReducer';

export default function Login() {
    const { t, i18n } = useTranslation('login');

    const form = useRef();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const message = useSelector((state) => state.session.errorMessage);

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        await AuthService.login(username, password);
        setLoading(false);
    };

    useEffect(() => {
        dispatch({ ...initialState, type: SET_USER });
    }, []);

    return (
        <div>
            <div id="logo-login"></div>
            <div id="main" className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <form
                            className="form-horizontal form-login"
                            encType="multipart/form-data"
                            id="frm"
                            onSubmit={handleLogin}
                            ref={form}
                        >
                            <div className="input-group m-b-20">
                                <div className="fg-line">
                                    <TextField
                                        label="Usuario"
                                        type="search"
                                        value={username}
                                        onChange={onChangeUsername}
                                        variant="standard"
                                        placeholder="Usuario"
                                        fullWidth
                                        required
                                    />
                                </div>
                            </div>
                            <div className="input-group m-b-20">
                                <div className="fg-line">
                                    <TextField
                                        label="Contraseña"
                                        type="password"
                                        value={password}
                                        onChange={onChangePassword}
                                        variant="standard"
                                        placeholder="Contraseña"
                                        fullWidth
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                                    <span>{t('signIn')}</span>
                                </button>
                            </div>
                            {message && (
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            )}
                        </form>
                    </div>
                    <div className="col-lg-8">
                        <div className="cont-det b-y">
                            <h3>{t('conecta')}</h3>
                            <p>{t('conectaDescription')}</p>
                        </div>
                        <div className="cont-det b-p">
                            <h3>{t('distribuye')}</h3>
                            <p>{t('distribuyeDescription')}</p>
                        </div>
                        <div className="cont-det b-g">
                            <h3>{t('personaliza')}</h3>
                            <p>{t('personalizaDescription')}</p>
                        </div>
                        <div className="cont-det b-v">
                            <h3>{t('facilita')}</h3>
                            <p>{t('facilitaDescription')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
