import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import { useTranslation } from 'react-i18next';

import { LOGOUT } from 'store/actions';

import AuthService from '../../../../services/auth.service';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    const clientId = useSelector((state) => state.session.clientId);
    const username = useSelector((state) => state.session.username);

    const logOut = () => {
        AuthService.logout().then(
            () => {},
            (error) => {
                const resMessage =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(resMessage);
            }
        );
        dispatch({ type: LOGOUT });
    };

    return (
        <div className="col-lg-6 pr-30" style={{ textAlign: 'right' }}>
            <p className="company" style={{ color: 'black' }}>
                {clientId}
            </p>
            <p className="username" style={{ color: 'black' }}>
                {username}
            </p>
            <Link className="log-out" href="/" underline="always" onClick={logOut}>
                {t('shared.signOut')}
            </Link>
        </div>
    );
};

export default ProfileSection;
