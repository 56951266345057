// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    selectedCompany: null,
    clientId: null,
    tiposReglaPermitidos: null,
    accessToken: null,
    isUserSuccessfullyLogedIn: false,
    canSwitchCompany: false,
    userId: null,
    username: '',
    errorLogin: false,
    errorMessage: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const getClientId = (company) => {
    if (company && company.companyUserRoles) {
        return company.companyUserRoles['mantenimiento NRE']?.items?.nombreEmpresa.value.toUpperCase();
    }
    return null;
};

const getTiposReglasPermitidos = (company) => {
    if (company && company.companyUserRoles) {
        return company.companyUserRoles['mantenimiento NRE']?.items?.tiposDeReglasPermitidos.value?.split(',');
    }
    return null;
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_COMPANY:
            return {
                ...state,
                selectedCompany: action.selectedCompany,
                clientId: getClientId(action.selectedCompany),
                tiposReglaPermitidos: getTiposReglasPermitidos(action.selectedCompany)
            };
        case actionTypes.SET_USER:
            return {
                ...state,
                accessToken: action.user?.AccessToken,
                isUserSuccessfullyLogedIn: action.user?.IsUserSuccessfullyLogedIn,
                canSwitchCompany: action.user?.CanSwitchCompany,
                userId: action.user?.UserId,
                username: action.username,
                errorLogin: action.errorLogin ? action.errorLogin : false,
                errorMessage: action.errorMessage
            };
        case actionTypes.LOGOUT:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default sessionReducer;
