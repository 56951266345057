import React from 'react';

const RuleContext = React.createContext({
    itemRefs: null,
    handleScroll: null,
    handleChangeExpanded: null,
    edit: false,
    handleAddCondition: null,
    handleDeleteCondition: null,
    rule: null
});

export default RuleContext;