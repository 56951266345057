// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SHOW_NOTIFICATION = '@customization/SHOW_NOTIFICATION';
export const SET_RULES_FILTERS = '@customization/SET_RULES_FILTERS';

//session
export const SET_COMPANY = '@session/SET_COMPANY';
export const SET_USER = '@session/SET_USER';
export const LOGOUT = '@session/LOGOUT';

//rules
export const SET_SEGMENTATIONS = '@rules/SET_SEGMENTATIONS';
export const SET_PRPOERTIES = '@rules/SET_PRPOERTIES';
export const SET_CONDITIONS = '@rules/SET_CONDITIONS';
export const SET_RULE_MENU = '@rules/SET_RULE_MENU';
