// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isOpen: [], // for active default menu
    opened: false,
    showNotification: false,
    notification: null,
    rulesFilters: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SHOW_NOTIFICATION:
            return {
                ...state,
                showNotification: action.showNotification,
                notification: { severity: action.severity, message: action.message }
            };
        case actionTypes.SET_RULES_FILTERS:
            return {
                ...state,
                rulesFilters: action.rulesFilters
            };
        default:
            return state;
    }
};

export default customizationReducer;
