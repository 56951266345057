import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import sessionReducer from './sessionReducer';
import rulesReducer from './rulesReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    session: sessionReducer,
    rules: rulesReducer
});

export default reducer;
